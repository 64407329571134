export const CoreIconPageList = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='list'>
				<path id='Vector' d='M8 6H21' stroke='#C0C7E3' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path
					id='Vector_2'
					d='M8 12H21'
					stroke='#C0C7E3'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_3'
					d='M8 18H21'
					stroke='#C0C7E3'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_4'
					d='M3 6H3.01'
					stroke='#C0C7E3'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_5'
					d='M3 12H3.01'
					stroke='#C0C7E3'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_6'
					d='M3 18H3.01'
					stroke='#C0C7E3'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	)
}
