import type { FunctionComponent, SVGProps } from 'react'
import { Text } from '@mantine/core'
import { NavLink } from 'react-router-dom'

import { useCoreAppNavbarStyle } from './core-app-navbar-style'

type Props = {
	text: string
	icon: FunctionComponent<SVGProps<SVGSVGElement>>
	href: string
	withBaseUrl: boolean
}

const HR_APP_URL = ''

export const CoreAppNavbarLink = ({ text, icon: Icon, href, withBaseUrl }: Props) => {
	const { classes } = useCoreAppNavbarStyle()

	return (
		<NavLink
			to={withBaseUrl ? `${HR_APP_URL}/${href}` : href}
			className={({ isActive }) => (isActive ? classes.linkActive : classes.link)}
		>
			<Icon style={{ marginBottom: '5px' }} />
			<Text className={classes.text}>{text}</Text>
		</NavLink>
	)
}
