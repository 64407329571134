import React from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import CoreApp from './core-app'

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path='/*' element={<CoreApp />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
)
