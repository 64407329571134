import { Navigate, useLocation } from 'react-router-dom'

type Props = {
	where: string
}

export const CoreRedirectAfterLogin = ({ where }: Props) => {
	const location = useLocation()

	const token = new URLSearchParams(location.search).get('token') || ''
	localStorage.setItem('token', token!)

	if (token) return <Navigate to={where} state={{ location }} replace />

	return <Navigate to={'/'} state={{ location }} replace />
}
