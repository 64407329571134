export const ENV_API_BASE_URL = `${import.meta.env.VITE_BE_SERVER_HOST}:${import.meta.env.VITE_BE_SERVER_PORT}`

export const ENV_AZURE_AUTH_URL = `${ENV_API_BASE_URL}/oauth2/authorize/azure?redirect_uri=${
	import.meta.env.VITE_OAUTH2_REDIRECT_URI
}:${import.meta.env.VITE_FE_SERVER_PORT}`

export const ENV_API_URL = `${import.meta.env.VITE_BE_SERVER_HOST}/api/v1/`

export const ENV_API_BASE_DATA_URL = import.meta.env.VITE_API_BASE_DATA_URL
export const ENV_VITE_API_VERSION = import.meta.env.VITE_API_VERSION
export const ENV_ACCESS_TOKEN = import.meta.env.ACCESS_TOKEN

export const ENV_ID_OFFICE_BUG = import.meta.env.VITE_ID_OFFICE_BUG
export const ENV_OFFICE = import.meta.env.VITE_OFFICE

export const ENV_ID_OFFICE_HEADSET = import.meta.env.VITE_ID_OFFICE_HEADSET

export const ENV_API_VERSION = import.meta.env.VITE_API_VERSION
