import { AppShell } from '@mantine/core'
import { Outlet } from 'react-router-dom'

import { CoreAppHeader } from './components/core-app-header/core-app-header'
import { CoreAppNavbar } from './components/core-app-navbar/core-app-navbar'

export const CoreLayout = () => {
	return (
		<AppShell padding='md' navbar={<CoreAppNavbar />} header={<CoreAppHeader />}>
			<Outlet />
		</AppShell>
	)
}
