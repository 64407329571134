import { ButtonStylesParams } from '@mantine/core'
import type { DefaultMantineColor, MantineThemeOverride, Tuple } from '@mantine/core'

export const THEME: MantineThemeOverride = {
	globalStyles: (theme) => ({
		body: {
			overflow: 'scroll',
		},

		'.mantine-1lj4opn:checked+.___ref-icon': {
			transform: 'scale(1.5)',
		},

		// START Modal
		'.assist-hr-modal-children': {
			padding: theme.spacing.md,
		},

		'.mantine-Modal-body .mantine-Group-root': {
			padding: theme.spacing.md,
			backgroundColor: '#F6F7FE',
		},
		// END Modal

		// START Scrollbar
		'::-webkit-scrollbar': {
			width: '6px !important',
			height: '6px !important',
			borderRadius: '10px !important',
		},

		'::-webkit-scrollbar-thumb': {
			width: '6px !important',
			background: 'rgb(192, 199, 227) !important',
			borderRadius: '10px !important',
		},

		'::-webkit-scrollbar-track': {
			background: 'rgb(246, 247, 254) !important',
			borderRadius: '6px !important',
		},
		// END Scrollbar
	}),

	colors: {
		assistBlue: [
			'#032ee81a',
			// '#EAE4FB',
			'#D5C8FC',
			'#BEABFF',
			'#A690FF',
			'#8C76FF',
			'#6D5CFE',
			'#E7E6E7',
			'#032EE8',
			'#0520AE',
			//'#0E1575',
			'#032EE8',
		],

		assistGray: [
			'#46507e19',
			'#CFCECF',
			'#B7B6B7',
			'#A09EA0',
			'#8B888B',
			'#757275',
			'#5E5C5E',
			'#4A494A',
			'#363536',
			//'#252325',
			'#46507E',
		],

		assistRed: [
			'#FBE1DC',
			'#FCC0B6',
			'#FF998C',
			'#FF7065',
			'#FF303C',
			'#E3002B',
			'#B90021',
			'#940018',
			'#700110',
			'#700110',
		],

		assistYellow: [
			'#FCE3B4',
			'#FCC622',
			'#E0AD01',
			'#C49800',
			'#A98201',
			'#896900',
			'#745800',
			'#5C4500',
			'#443301',
			'#171104',
		],

		assistGreen: [
			'#97FADC',
			'#54E6C1',
			'#3CCCA9',
			'#34B393',
			'#2B9A7F',
			'#228069',
			'#1A6956',
			'#135343',
			'#0D3D31',
			'#092921',
		],

		assistBlack: ['#010C3F', '#010C3F', '#010C3F', '#010C3F', '#010C3F', '#010C3F', '#010C3F', '#010C3F', '#010C3F'],

		primaryColor: ['assistBlue'],
	},

	fontFamily: 'Inter,sans-serif',

	fontSizes: {
		xs: '14px',
		sm: '16px',
		md: '20px',
		lg: '24px',
		xl: '32px',
	},

	headings: {
		fontWeight: 600,
		fontFamily: 'Inter,sans-serif',

		sizes: {
			h1: { fontSize: '48px' },
			h2: { fontSize: '28px' },
			h3: { fontSize: '24px' },
			h4: { fontSize: '20px' },
			h5: { fontSize: '16px' },
			h6: { fontSize: '14px' },
		},
	},

	components: {
		Button: {
			defaultProps: {},

			// TODO: Style all the buttons based on hr-app design
			styles: (theme, params: ButtonStylesParams, { variant }) => ({
				root: {
					padding: '0 1.875rem',
					backgroundColor: variant === 'filled' ? theme.colors[params.color || theme.primaryColor]?.[5] : undefined,
				},
			}),
		},

		Notification: {},

		Accordion: {
			styles: () => ({
				item: {
					backgroundColor: 'white',
					border: '1px solid #E7E6E7',
					'&[data-active]': { border: '1px solid #E7E6E7' },
				},

				chevron: {
					color: '#032EE8',
					'&[data-rotate]': {
						transform: 'rotate(90deg)',
					},
				},
			}),
		},

		Modal: {
			styles: () => ({
				body: {
					padding: 0,
				},
			}),
		},

		Tooltip: {
			styles: () => ({
				tooltip: {
					border: '1px solid #d4d4d5',
				},

				arrow: {
					border: '1px solid #d4d4d5',
				},
			}),
		},

		ScrollArea: {
			styles: () => ({
				scrollbar: {
					width: '6px ',
					borderRadius: '10px ',
				},
				thumb: {
					width: '6px ',
					background: 'rgb(192, 199, 227)!important ',
					borderRadius: '10px',
				},
			}),
		},

		Text: {
			styles: () => ({
				root: {
					fontWeight: 400,
				},
			}),
		},

		Title: {
			styles: () => ({
				root: {
					color: '#010C3F',
				},
			}),
		},

		Badge: {
			styles: () => ({
				root: {
					borderRadius: '5px',
					backgroundColor: '#E0E5FA',
					color: '#010C3F',

					padding: '4px 16px',
					gap: '8px',
				},
				inner: {
					fontSize: '12px',
					fontStyle: 'normal',
					lineHeight: '16px',
					fontWeight: 600,
					textTransform: 'capitalize',
				},
			}),
		},

		Anchor: {
			styles: (theme) => ({
				root: {
					fontSize: '16px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '32px',
					letterSpacing: '0.12px',
					textDecorationLine: 'underline',
					textTransform: 'capitalize',
					color: theme.colors.blue[7],
				},
			}),
		},

		Input: {
			styles: () => ({
				input: {
					borderRadius: '4px',
					border: '1px solid #929BC98c',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '24px',
				},
			}),
		},

		// InputWrapper: {
		// styles: () => ({
		// 	label: {
		// 		fontSize: '12px',
		// 		fontStyle: 'bold',
		// 		fontWeight: 600,
		// 		lineHeight: '16px',
		// 		color: '#010C3F',
		// 	},

		// 	required: {
		// 		color: '#010C3F',
		// 		fontSize: '12px',
		// 		fontStyle: 'bold',
		// 		fontWeight: '600',
		// 	},

		// 	error: {
		// 		marginTop: '5px',
		// 	},
		// }),
		// },

		Radio: {
			styles: () => ({
				radio: {
					'&:checked': {
						background: '#fff',
						border: '2px solid #032EE8',
					},
				},
				icon: {
					color: '#032EE8',
				},

				label: {
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '24px',
				},
			}),
		},

		Select: {
			styles: () => ({
				item: {
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '24px',
				},
			}),
		},

		RichTextEditor: {
			styles: () => ({
				root: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					border: '1px solid #929BC98c',
					borderRadius: '8px',
				},
				toolbar: {
					display: 'flex',
					borderTop: '1px solid #929BC98c',
					borderRadius: '0 0 8px 8px',
					borderBottom: 'none',
				},
				control: {
					border: 'none',
					transform: 'scale(1.2)',
				},
				content: {
					fontSize: '14px',
					lineHeight: '16px',
					borderRadius: ' 8px 8px 0 0',
				},
			}),
		},

		AppShell: {
			styles: () => ({
				main: {
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'center',
				},
			}),
		},
	},
}

type ExtendedCustomColors =
	| 'assistBlue'
	| 'assistGray'
	| 'assistRed'
	| 'assistYellow'
	| 'assistGreen'
	| 'assistBlack'
	| DefaultMantineColor

declare module '@mantine/core' {
	export type MantineThemeColorsOverride = {
		colors: Record<ExtendedCustomColors, Tuple<string, 10>>
	}
}
