import { ENV_ID_OFFICE_BUG, ENV_ID_OFFICE_HEADSET, ENV_OFFICE } from '@assistant/utils'

export const CORE_ROUTES = {
	LOGIN: 'login',
	ROOT: 'https://hr-dev.assist.ro',
	VERSION_HISTORY: 'https://hr-dev.assist.ro/version-history',
	REPORT_BUG: `${ENV_OFFICE}/${ENV_ID_OFFICE_BUG}`,
	IMPROVEMENT: `${ENV_OFFICE}/${ENV_ID_OFFICE_HEADSET}`,
	PROFILE: 'profile',
	OVERVIEW: 'overview',
}
