export const CoreIconCertifications = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g>
				<path
					id='Vector'
					d='M6.63843 6.46094H13.7084C13.9062 6.46177 14.0993 6.52122 14.2633 6.63178C14.4273 6.74235 14.5548 6.89905 14.6297 7.08209C14.7046 7.26512 14.7236 7.46626 14.6842 7.66007C14.6448 7.85388 14.5489 8.03167 14.4084 8.17094L10.1684 12.4109C9.9838 12.5994 9.7322 12.7072 9.46843 12.7109H2.37843C2.18236 12.7062 1.99199 12.644 1.83103 12.532C1.67006 12.4199 1.54559 12.263 1.47312 12.0807C1.40065 11.8985 1.38337 11.699 1.42343 11.507C1.46349 11.315 1.55912 11.139 1.69843 11.0009L5.93843 6.76094C6.12306 6.57252 6.37466 6.46469 6.63843 6.46094V6.46094ZM17.5384 17.3609C17.5379 17.4932 17.5111 17.6241 17.4596 17.746C17.4081 17.8679 17.3329 17.9783 17.2384 18.0709L12.9984 22.3109C12.8587 22.448 12.6817 22.541 12.4896 22.5784C12.2975 22.6159 12.0986 22.5961 11.9176 22.5216C11.7366 22.447 11.5814 22.321 11.4714 22.1592C11.3613 21.9973 11.3012 21.8067 11.2984 21.6109V14.5409C11.2977 14.4093 11.3229 14.2789 11.3727 14.157C11.4224 14.0352 11.4957 13.9244 11.5884 13.8309L15.8284 9.59094C15.9682 9.4539 16.1451 9.36089 16.3373 9.32345C16.5294 9.28601 16.7283 9.30578 16.9093 9.38031C17.0903 9.45484 17.2454 9.58085 17.3555 9.74272C17.4655 9.9046 17.5257 10.0952 17.5284 10.2909V17.3609H17.5384Z'
					fill='#343D65'
				/>
				<path
					id='Vector_2'
					d='M5.77879 13.1899C6.87562 10.2248 8.82941 7.65278 11.3918 5.80091C13.9541 3.94904 17.0092 2.90095 20.1688 2.7899C20.3069 2.78425 20.4446 2.80729 20.5734 2.85756C20.7021 2.90783 20.819 2.98423 20.9167 3.08196C21.0144 3.17968 21.0909 3.29659 21.1411 3.42533C21.1914 3.55406 21.2144 3.69181 21.2088 3.8299C21.0977 6.98943 20.0496 10.0446 18.1978 12.6069C16.3459 15.1693 13.7739 17.1231 10.8088 18.2199C10.6004 18.297 10.3722 18.3025 10.1604 18.2355C9.94849 18.1685 9.76498 18.0328 9.63879 17.8499C8.68763 16.486 7.5027 15.3011 6.13879 14.3499C5.95979 14.2229 5.82756 14.0404 5.7625 13.8308C5.69744 13.6212 5.70317 13.396 5.77879 13.1899V13.1899ZM6.36879 15.7599C7.04204 16.3312 7.66746 16.9566 8.23879 17.6299C8.34522 17.7562 8.41871 17.9069 8.4527 18.0685C8.48669 18.2301 8.48011 18.3977 8.43356 18.5561C8.38701 18.7146 8.30194 18.8591 8.18593 18.9767C8.06993 19.0942 7.92662 19.1812 7.76879 19.2299C6.97879 19.4799 6.16879 19.6499 5.36879 19.7699C5.21458 19.7933 5.05704 19.7803 4.90875 19.732C4.76046 19.6836 4.62554 19.6012 4.51477 19.4914C4.40399 19.3816 4.32044 19.2474 4.27078 19.0996C4.22113 18.9517 4.20674 18.7943 4.22879 18.6399C4.34879 17.8199 4.52879 17.0199 4.75879 16.2299C4.80746 16.0721 4.89446 15.9288 5.01203 15.8127C5.1296 15.6967 5.27407 15.6117 5.43254 15.5651C5.59101 15.5186 5.75854 15.512 5.92017 15.546C6.08179 15.58 6.23249 15.6535 6.35879 15.7599H6.36879Z'
					fill='#929BC9'
				/>
				<path
					id='Vector_3'
					d='M7.22997 10.2616C9.89618 11.8677 12.128 14.1029 13.73 16.7716C12.81 17.3516 11.83 17.8416 10.81 18.2216C10.6016 18.2987 10.3734 18.3042 10.1615 18.2372C9.94968 18.1702 9.76616 18.0345 9.63997 17.8516C8.68881 16.4877 7.50389 15.3028 6.13997 14.3516C5.96097 14.2246 5.82875 14.0422 5.76369 13.8325C5.69863 13.6229 5.70435 13.3977 5.77997 13.1916C6.15997 12.1616 6.64997 11.1916 7.22997 10.2616V10.2616ZM17.62 3.10162C18.46 2.93162 19.32 2.83162 20.17 2.80162C20.3081 2.79597 20.4458 2.81901 20.5745 2.86928C20.7033 2.91955 20.8202 2.99595 20.9179 3.09367C21.0156 3.1914 21.092 3.30831 21.1423 3.43704C21.1926 3.56578 21.2156 3.70353 21.21 3.84162C21.18 4.70162 21.08 5.55162 20.91 6.39162C19.9507 5.16563 18.846 4.06086 17.62 3.10162V3.10162ZM13.71 10.3016C13.5241 10.1158 13.3767 9.89519 13.2762 9.65241C13.1756 9.40962 13.1239 9.14941 13.1239 8.88662C13.1239 8.35589 13.3347 7.8469 13.71 7.47162C14.0853 7.09633 14.5942 6.8855 15.125 6.8855C15.3878 6.8855 15.648 6.93726 15.8908 7.03783C16.1335 7.13839 16.3541 7.28579 16.54 7.47162C16.9153 7.8469 17.1261 8.35589 17.1261 8.88662C17.1261 9.41734 16.9153 9.92633 16.54 10.3016C16.1647 10.6769 15.6557 10.8877 15.125 10.8877C14.5942 10.8877 14.0853 10.6769 13.71 10.3016V10.3016Z'
					fill='#343D65'
				/>
			</g>
		</svg>
	)
}
