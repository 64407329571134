export const CoreIconLearning = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='learning'>
				<path
					id='secondary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6.84345 23.7456C5.3141 24.5835 3.52666 23.2302 3.81874 21.4555L4.80355 15.4716L0.631817 11.2339C-0.605456 9.97702 0.0772863 7.78722 1.78715 7.5283L7.55235 6.65527L10.1306 1.21101C10.8953 -0.403671 13.1047 -0.403671 13.8694 1.21101L16.4477 6.65527L22.2128 7.5283C23.9227 7.78722 24.6055 9.97702 23.3682 11.2339L19.1964 15.4716L20.1813 21.4555C20.4733 23.2302 18.6859 24.5835 17.1565 23.7456L12 20.9205L6.84345 23.7456Z'
					fill='#343D65'
				/>
				<path
					id='primary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.56299 10.1446C9.56299 11.5621 10.6546 12.7112 12.0012 12.7112C13.3478 12.7112 14.4394 11.5621 14.4394 10.1446C14.4394 8.72719 13.3478 7.57812 12.0012 7.57812C10.6546 7.57812 9.56299 8.72719 9.56299 10.1446ZM16.2681 17.7939C16.2681 18.5303 15.6711 19.1272 14.9347 19.1272H9.06771C8.33133 19.1272 7.73438 18.5303 7.73438 17.7939V17.2023C7.73438 15.4305 9.0989 13.9942 10.7821 13.9942H13.2203C14.9035 13.9942 16.2681 15.4305 16.2681 17.2023V17.7939Z'
					fill='#929BC9'
				/>
			</g>
		</svg>
	)
}
