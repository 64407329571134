import { createStyles, getStylesRef } from '@mantine/core'

export const useCoreAppNavbarStyle = createStyles((theme) => ({
	main: {
		display: 'flex',
		backgroundColor: '#010C3F',
		height: '100vh',
		padding: '96px 16px 167px 16px',
		flexDirection: 'column',
		alignItems: 'center',
		top: 0,
	},

	link: {
		...theme.fn.focusStyles(),
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		fontSize: '14px',
		color: 'rgb(146, 159, 223)',
		padding: '12px 33px 12px 14px',
		alignSelf: 'stretch',
		fontWeight: 600,
		fontFamily: 'Inter, sans-serif',
		backgroundColor: ' rgb(1, 12, 63)',
		width: '192px',
		height: '48px',

		'&:hover': {
			color: 'rgb(146, 159, 223)',
			backgroundColor: ' rgb(1, 12, 63)',
		},
	},

	href: {
		textDecoration: 'none',
	},

	text: {
		color: 'inherit',
		fontSize: '14px',
		fontFamily: 'Inter, sans-serif',
		marginLeft: '8px',
		fontWeight: 600,
		'&:active': {
			color: 'rgba(255, 255, 255, 1)',
		},
	},

	linkActive: {
		...theme.fn.focusStyles(),
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		fontSize: '14px',

		padding: '12px 33px 12px 14px',
		alignSelf: 'stretch',
		fontWeight: 600,
		fontFamily: 'Inter, sans-serif',

		width: '192px',
		height: '48px',

		backgroundColor: 'rgb(255 255 255 / 30%)',
		color: '#fff',

		[`& .${getStylesRef('icon')}`]: {
			color: theme.colorScheme === 'dark' ? theme.white : theme.black,
		},
		borderRadius: '8px',

		'&:hover': {
			backgroundColor: 'rgb(255 255 255 / 30%)',
			color: 'rgba(255, 255, 255, 1)',
		},
	},
}))
