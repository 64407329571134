export const CoreIconDepartments = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='departments'>
				<g id='Group'>
					<path
						id='primary'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14 12H19L22 15L19 18H14V22H10V18H4C3.44772 18 3 17.5523 3 17V13C3 12.4477 3.44772 12 4 12H10V11H14V12Z'
						fill='#8B93BE'
					/>
					<path
						id='secondary'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4H20C20.5523 4 21 4.44772 21 5V9C21 9.55228 20.5523 10 20 10H5L2 7L5 4H10Z'
						fill='white'
					/>
				</g>
			</g>
		</svg>
	)
}
