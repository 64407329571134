export const CoreIconDevices = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='devices'>
				<path
					id='primary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H6ZM15.2929 8.29289L14.1169 9.46891C13.474 9.16807 12.7566 9 12 9C9.23858 9 7 11.2386 7 14C7 16.7614 9.23858 19 12 19C14.7614 19 17 16.7614 17 14V9C17 8.1091 15.9229 7.66293 15.2929 8.29289Z'
					fill='#929BC9'
				/>
				<path
					id='secondary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5ZM19 4C19 4.55228 18.5523 5 18 5C17.4477 5 17 4.55228 17 4C17 3.44772 17.4477 3 18 3C18.5523 3 19 3.44772 19 4ZM14 14C14 15.1046 13.1046 16 12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14ZM19 20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20C17 19.4477 17.4477 19 18 19C18.5523 19 19 19.4477 19 20ZM6 21C6.55228 21 7 20.5523 7 20C7 19.4477 6.55228 19 6 19C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21ZM12 6H8C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8H12C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6Z'
					fill='#343D65'
				/>
			</g>
		</svg>
	)
}
