import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { getUserIdFromLocalStorage, useGetEmployee } from '@assistant/utils'

type Props = {
	allowedRoles?: string[]
}

type Error = {
	response: { data: { path: string } }
}

export const AssistantRestrictedRoute = ({ allowedRoles = [] }: Props) => {
	const employeeId = getUserIdFromLocalStorage()

	const location = useLocation()
	const { data, isSuccess, error } = useGetEmployee(employeeId)

	const myError: Error = error as Error
	if (myError?.response?.data.path === '/login') return <Navigate to='/login' state={{ from: location }} replace />

	const isNotReadyToDecide = !isSuccess && employeeId
	if (isNotReadyToDecide) return null

	const isAuthenticated = !!data?.id
	const noRolesRequired = isAuthenticated && allowedRoles.length === 0

	const rolesAreMatching = data?.roles?.some((role) => allowedRoles?.includes(role.name))
	const isAuthorized = noRolesRequired || rolesAreMatching

	if (!isAuthenticated) return <Navigate to='/login' state={{ from: location }} replace />
	else if (!isAuthorized) return <Navigate to='/unauthorized' state={{ from: location }} replace />
	else if (isAuthorized) return <Outlet />
}
