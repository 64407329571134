import { useState } from 'react'
import { Button, Flex, Navbar, ScrollArea } from '@mantine/core'

import { getUserIdFromLocalStorage } from '@assistant/utils'

import { HR_APP_NAVBAR_WIDTH, HR_APP_NAVBAR_BREAK_POINT } from '../../constants/core-breakpoint-constants'

import { CoreIconDepartments } from '../../assets/icons/core-icon-departments'
import { CoreIconHome } from '../../assets/icons/core-icon-home'
import { CoreIconCertifications } from '../../assets/icons/core-icon-certifications'
import { CoreIconPuzzle } from '../../assets/icons/core-icon-puzzle'
import { CoreIconDevices } from '../../assets/icons/core-icon-devices'
import { CoreIconLearning } from '../../assets/icons/core-icon-learning'
import { CoreIconPeople } from '../../assets/icons/core-icon-people'
import { CoreIconProjects } from '../../assets/icons/core-icon-projects'
import { CoreIconSettings } from '../../assets/icons/core-icon-settings'
import { CoreIconProfile } from '../../assets/icons/core-icon-profile'
import { CoreIconRecruitment } from '../../assets/icons/core-icon-recruitment'
import { CoreIconRectangle } from '../../assets/icons/core-icon-rectangle'
import { CoreIconOnboarding } from '../../assets/icons/core-icon-onboarding'
import { CoreIconChevronDown } from '../../assets/icons/core-icon-chevron-down'
import { CoreIconCalendar } from '../../assets/icons/core-icon-calendar'

import { useCoreAppNavbarStyle } from './core-app-navbar-style'
import { CoreAppNavbarLink } from './core-app-navbar-link'

export const CoreAppNavbar = () => {
	const { classes } = useCoreAppNavbarStyle()
	const [openedDev, setOpenedDev] = useState(false)
	const [openedHoliday, setOpenedHoliday] = useState(false)
	const [openedRecruitment, setOpenedRecruitment] = useState(false)
	const [openedOnboarding, setOpenedOnboarding] = useState(false)
	const [openedSkills, setOpenedSkills] = useState(false)

	const userId = getUserIdFromLocalStorage()

	return (
		<Navbar
			width={{ sm: HR_APP_NAVBAR_WIDTH }}
			hidden
			hiddenBreakpoint={HR_APP_NAVBAR_BREAK_POINT}
			className={classes.main}
		>
			<ScrollArea>
				<Navbar.Section grow>
					<Flex direction='column' mb={35}>
						<CoreAppNavbarLink text='Home' icon={CoreIconHome} href={'home'} withBaseUrl />

						<CoreAppNavbarLink
							text='My Profile'
							icon={CoreIconProfile}
							href={`profile/${userId}/overview`}
							withBaseUrl
						/>

						<Button
							className={classes.link}
							leftIcon={<CoreIconPuzzle />}
							onClick={() => setOpenedDev(!openedDev)}
							rightIcon={<CoreIconChevronDown />}
						>
							Development
						</Button>

						<div hidden={!openedDev}>
							<CoreAppNavbarLink text='Goals' icon={CoreIconRectangle} href='development/goals' withBaseUrl />
							<CoreAppNavbarLink text='1-on-1s' icon={CoreIconRectangle} href='development/one' withBaseUrl />

							<CoreAppNavbarLink text='promotions' icon={CoreIconRectangle} href='development/promotions' withBaseUrl />
						</div>

						<CoreAppNavbarLink text='Courses' icon={CoreIconLearning} href='courses' withBaseUrl />
					</Flex>

					<Flex direction='column' mb={35}>
						<CoreAppNavbarLink text='Events' icon={CoreIconDepartments} href='/events/all' withBaseUrl={false} />
						<CoreAppNavbarLink text='People' icon={CoreIconPeople} href='people/employed' withBaseUrl />
						<CoreAppNavbarLink text='People Skills' icon={CoreIconPuzzle} href='skills/metrics' withBaseUrl />

						<CoreAppNavbarLink
							text='People Certifications'
							icon={CoreIconCertifications}
							href='certifications'
							withBaseUrl
						/>
					</Flex>

					<Flex direction='column' mb={35}>
						<Button
							className={classes.link}
							leftIcon={<CoreIconCalendar />}
							onClick={() => setOpenedHoliday(!openedHoliday)}
							rightIcon={<CoreIconChevronDown />}
						>
							Holidays
						</Button>

						<div hidden={!openedHoliday}>
							<CoreAppNavbarLink text='Calendar' icon={CoreIconRectangle} href='holidays/calendar' withBaseUrl />

							<CoreAppNavbarLink text='All Requests' icon={CoreIconRectangle} href='holidays/requests' withBaseUrl />

							<CoreAppNavbarLink text='Pending' icon={CoreIconRectangle} href='holidays/pending' withBaseUrl />
							<CoreAppNavbarLink text='My Holidays' icon={CoreIconRectangle} href='holidays/my' withBaseUrl />
						</div>

						<CoreAppNavbarLink text='Projects' icon={CoreIconProjects} href='projects' withBaseUrl />
					</Flex>

					<Flex direction='column' mb={35}>
						<Button
							className={classes.link}
							leftIcon={<CoreIconOnboarding />}
							onClick={() => setOpenedOnboarding(!openedOnboarding)}
							rightIcon={<CoreIconChevronDown />}
						>
							Onboarding
						</Button>

						<div hidden={!openedOnboarding}>
							<CoreAppNavbarLink text='Setup List' icon={CoreIconRectangle} href='onboarding/setupList' withBaseUrl />

							<CoreAppNavbarLink text=' My tasks' icon={CoreIconRectangle} href='onboarding/myTasks' withBaseUrl />
						</div>

						<Button
							className={classes.link}
							leftIcon={<CoreIconRecruitment />}
							onClick={() => setOpenedRecruitment(!openedRecruitment)}
							rightIcon={<CoreIconChevronDown />}
						>
							Recruitment
						</Button>
						<div hidden={!openedRecruitment}>
							<CoreAppNavbarLink
								text='Candidates List'
								icon={CoreIconRectangle}
								href='recruitment/candidates/list/professionals'
								withBaseUrl
							/>

							<CoreAppNavbarLink
								text='Job Requests'
								icon={CoreIconRectangle}
								href='recruitment/job-request'
								withBaseUrl
							/>

							<CoreAppNavbarLink text='Archive' icon={CoreIconRectangle} href='recruitment/archive' withBaseUrl />
						</div>
					</Flex>

					<Flex direction='column' mb={35}>
						<CoreAppNavbarLink text='Devices' icon={CoreIconDevices} href='devices' withBaseUrl />

						<CoreAppNavbarLink text='Settings' icon={CoreIconSettings} href='settings/technologies' withBaseUrl />

						<CoreAppNavbarLink text='Roles' icon={CoreIconSettings} href='roles' withBaseUrl />

						<Button
							className={classes.link}
							leftIcon={<CoreIconPuzzle />}
							onClick={() => setOpenedSkills(!openedSkills)}
							rightIcon={<CoreIconChevronDown />}
						>
							Skill
						</Button>

						<div hidden={!openedSkills}>
							<CoreAppNavbarLink
								text='Skill Categories'
								icon={CoreIconRectangle}
								href='skills/categories'
								withBaseUrl
							/>

							<CoreAppNavbarLink text='Skill Set' icon={CoreIconRectangle} href='skills/set' withBaseUrl />
						</div>
					</Flex>
				</Navbar.Section>
			</ScrollArea>
		</Navbar>
	)
}
