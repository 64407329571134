export const CoreIconProjects = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='projects'>
				<path
					id='secondary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10.8039 17.9808C11.3454 18.0891 11.8723 17.7379 11.9806 17.1963L13.9806 7.19631C14.0889 6.65475 13.7377 6.12792 13.1961 6.01961C12.6546 5.9113 12.1277 6.26252 12.0194 6.80408L10.0194 16.8041C9.91111 17.3456 10.2623 17.8725 10.8039 17.9808ZM8.70711 13.2931C9.09763 13.6836 9.09763 14.3168 8.70711 14.7073C8.31658 15.0978 7.68342 15.0978 7.29289 14.7073L5.29289 12.7073C4.90237 12.3168 4.90237 11.6836 5.29289 11.2931L7.29289 9.29309C7.68342 8.90256 8.31658 8.90256 8.70711 9.29309C9.09763 9.68361 9.09763 10.3168 8.70711 10.7073L7.41421 12.0002L8.70711 13.2931ZM15.2929 13.2931C14.9024 13.6836 14.9024 14.3168 15.2929 14.7073C15.6834 15.0978 16.3166 15.0978 16.7071 14.7073L18.7071 12.7073C19.0976 12.3168 19.0976 11.6836 18.7071 11.2931L16.7071 9.29309C16.3166 8.90256 15.6834 8.90256 15.2929 9.29309C14.9024 9.68361 14.9024 10.3168 15.2929 10.7073L16.5858 12.0002L15.2929 13.2931Z'
					fill='#929BC9'
				/>
			</g>
		</svg>
	)
}
