import jwt_decode from 'jwt-decode'

import type { TokenValues } from '../types/general-types'
import { logger } from '../utils/logger'

export const getUserIdFromLocalStorage = (): string | null => {
	const token = localStorage.getItem('token') || ''
	let tokenValues: TokenValues | null = null

	try {
		tokenValues = jwt_decode(token)
	} catch (error) {
		let message = 'Unknown Error'
		if (error instanceof Error) message = error.message
		logger(message)
		return null
	}

	return tokenValues?.sub || ''
}
