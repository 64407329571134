export const CoreIconHeadset = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='HeadSet'>
				<g id='Group'>
					<path
						id='Vector'
						d='M5.63639 18.3626H3.8182C2.81866 18.3626 2 17.5535 2 16.5444V12.908C2 11.9085 2.80914 11.0898 3.8182 11.0898H5.63639V18.3626Z'
						stroke='#C0C7E3'
						strokeWidth='1.9091'
						strokeMiterlimit='10'
					/>
					<path
						id='Vector_2'
						d='M20.1815 18.3626H18.3633V11.0898H20.1815C21.181 11.0898 21.9997 11.899 21.9997 12.908V16.5444C21.9902 17.544 21.181 18.3626 20.1815 18.3626Z'
						stroke='#C0C7E3'
						strokeWidth='1.9091'
						strokeMiterlimit='10'
					/>
					<path
						id='Vector_3'
						d='M19.2689 18.3633C19.2689 20.3719 17.6411 21.9997 15.6325 21.9997H11.9961'
						stroke='#C0C7E3'
						strokeWidth='1.9091'
						strokeMiterlimit='10'
					/>
					<path
						id='Vector_4'
						d='M4.73047 11.091V9.27279C4.73047 5.25562 7.98609 2 12.0033 2C16.0204 2 19.276 5.25562 19.276 9.27279V11.091'
						stroke='#C0C7E3'
						strokeWidth='1.9091'
						strokeMiterlimit='10'
					/>
				</g>
			</g>
		</svg>
	)
}
