export const CoreIconBug = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g>
				<path
					d='M12 13.0005V21.0005M5 9.0005C5 8.47007 5.21071 7.96136 5.58579 7.58629C5.96086 7.21121 6.46957 7.0005 7 7.0005H17C17.5304 7.0005 18.0391 7.21121 18.4142 7.58629C18.7893 7.96136 19 8.47007 19 9.0005V14.0005C19 15.857 18.2625 17.6375 16.9497 18.9502C15.637 20.263 13.8565 21.0005 12 21.0005V21.0005C10.1435 21.0005 8.36301 20.263 7.05025 18.9502C5.7375 17.6375 5 15.857 5 14.0005V9.0005ZM8 6.0005V5.5755C8 4.5945 8.384 3.6155 9.326 3.3375C10.851 2.8875 13.149 2.8875 14.674 3.3375C15.616 3.6155 16 4.5945 16 5.5755V6.0005H8ZM18.5 7.5005L22 4.0005L18.5 7.5005ZM5.5 7.5005L2 4.0005L5.5 7.5005ZM6 18.0005L2 21.0005L6 18.0005ZM5 12.0005H1.5H5ZM22.5 12.0005H19H22.5ZM18 18.0005L22 21.0005L18 18.0005Z'
					stroke='#C0C7E3'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	)
}
