import axios from 'axios'

import { ENV_API_BASE_URL, ENV_API_VERSION } from '../constants/env-constants'

const baseURL = `${ENV_API_BASE_URL}${ENV_API_VERSION}`

const apiGet = async (url: string) => {
	const token = localStorage.getItem('token')
	const headers = { ...(token && { Authorization: `Bearer ${token}` }) }

	return await axios({ url, baseURL, headers, method: 'GET' })
}

const apiPost = async ({ url, data }: { url: string; data?: unknown }) => {
	const token = localStorage.getItem('token')
	const headers = { ...(token && { Authorization: `Bearer ${token}` }) }

	return await axios({ url, baseURL, headers, method: 'POST', data })
}

const apiDelete = async (url: string) => {
	const token = localStorage.getItem('token')
	const headers = { ...(token && { Authorization: `Bearer ${token}` }) }

	return await axios({ url, baseURL, headers, method: 'DELETE' })
}

export const apiClient = {
	get: apiGet,
	post: apiPost,
	delete: apiDelete,
}
