export const CoreIconPuzzle = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='icon-puzzle'>
				<path
					id='Path'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M18.0837 14.5804C18.0583 14.5757 18.0455 14.5652 18.0497 14.547C17.4881 14.8245 16.8242 15 15.9845 15C11.9753 15 11.9753 11 7.96611 11C7.17439 11 6.53901 11.156 5.99823 11.4064L5.89844 11.436C5.96237 11.3042 5.99823 11.1563 5.99823 11V8C5.99823 6.89543 6.89366 6 7.99823 6H10.9982C11.5505 6 11.9982 5.55228 11.9982 5V4C11.9982 2.89543 12.8937 2 13.9982 2C15.1028 2 15.9982 2.89543 15.9982 4V5C15.9982 5.55228 16.4459 6 16.9982 6H19.9982C21.1028 6 21.9982 6.89543 21.9982 8V11C21.9982 11.5523 21.5505 12 20.9982 12H19.9982C18.8937 12 17.9982 12.8954 17.9982 14C17.9982 14.2018 18.0281 14.3967 18.0837 14.5804V14.5804Z'
					fill='#929BC9'
				/>
				<path
					id='secondary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M22 17V20C22 21.1046 21.1046 22 20 22H17C16.4477 22 16 21.5523 16 21V20C16 18.8954 15.1046 18 14 18C12.8954 18 12 18.8954 12 20V21C12 21.5523 11.5523 22 11 22H8C6.89543 22 6 21.1046 6 20V17C6 16.4477 5.55228 16 5 16H4C2.89543 16 2 15.1046 2 14C2 12.8954 2.89543 12 4 12H5C5.55228 12 6 11.5523 6 11V10.4064C6.54078 10.156 7.17616 10 7.96788 10C11.9771 10 11.9771 14 15.9862 14C16.826 14 17.4899 13.8245 18.0515 13.547C18.0178 13.6926 18 13.8442 18 14C18 15.1046 18.8954 16 20 16H21C21.5523 16 22 16.4477 22 17Z'
					fill='#343D65'
				/>
			</g>
		</svg>
	)
}
