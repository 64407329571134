export const CoreIconRectangle = () => {
	return (
		<svg
			id='visual'
			viewBox='0 0 24 24'
			width='24'
			height='24'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			version='1.1'
		>
			<rect x='0' y='0' width='20' height='20' fill='#010C3F'></rect>
		</svg>
	)
}
