import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MantineProvider } from '@mantine/core'

import { CoreRouter } from './core-router'
import { THEME } from './constants/theme-constants'

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnMount: false, refetchOnWindowFocus: false } },
})

function CoreApp() {
	return (
		<QueryClientProvider client={queryClient}>
			<MantineProvider theme={THEME} withGlobalStyles withNormalizeCSS>
				<CoreRouter />
			</MantineProvider>
		</QueryClientProvider>
	)
}

export default CoreApp
