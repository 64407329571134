export const CoreIconRecruitment = () => {
	return (
		<svg
			className='icon-class'
			width='20'
			height='20'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.66656 6.72222C3.66656 7.94952 4.66149 8.94444 5.88879 8.94444C7.11609 8.94444 8.11101 7.94952 8.11101 6.72222C8.11101 5.49492 7.11609 4.5 5.88879 4.5C4.66149 4.5 3.66656 5.49492 3.66656 6.72222ZM9.77778 13.3888C9.77778 14.0024 9.28032 14.4999 8.66667 14.4999H3.11111C2.49746 14.4999 2 14.0024 2 13.3888V12.8332C2 11.2991 3.24365 10.0554 4.77778 10.0554H7C8.53413 10.0554 9.77778 11.2991 9.77778 12.8332V13.3888Z'
				fill='#343D65'
			></path>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.9166 6.72222C14.9166 7.94952 15.9115 8.94444 17.1388 8.94444C18.3661 8.94444 19.361 7.94952 19.361 6.72222C19.361 5.49492 18.3661 4.5 17.1388 4.5C15.9115 4.5 14.9166 5.49492 14.9166 6.72222ZM21.0278 13.3888C21.0278 14.0024 20.5303 14.4999 19.9167 14.4999H14.3611C13.7475 14.4999 13.25 14.0024 13.25 13.3888V12.8332C13.25 11.2991 14.4937 10.0554 16.0278 10.0554H18.25C19.7841 10.0554 21.0278 11.2991 21.0278 12.8332V13.3888Z'
				fill='#343D65'
			></path>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.91656 9.22222C9.91656 10.4495 10.9115 11.4444 12.1388 11.4444C13.3661 11.4444 14.361 10.4495 14.361 9.22222C14.361 7.99492 13.3661 7 12.1388 7C10.9115 7 9.91656 7.99492 9.91656 9.22222ZM16.0278 15.8888C16.0278 16.5024 15.5303 16.9999 14.9167 16.9999H9.36111C8.74746 16.9999 8.25 16.5024 8.25 15.8888V15.3332C8.25 13.7991 9.49365 12.5554 11.0278 12.5554H13.25C14.7841 12.5554 16.0278 13.7991 16.0278 15.3332V15.8888Z'
				fill='#929BC9'
			></path>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.3477 17.207C15.7994 17.207 18.5977 14.4088 18.5977 10.957C18.5977 7.50525 15.7994 4.70703 12.3477 4.70703C8.89588 4.70703 6.09766 7.50525 6.09766 10.957C6.09766 14.4088 8.89588 17.207 12.3477 17.207Z'
			></path>
		</svg>
	)
}
