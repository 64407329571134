import { createStyles, rem } from '@mantine/core'

import { HR_APP_HEADER_TABLET_BREAK_POINT, HR_APP_NAVBAR_WIDTH } from '../../constants/core-breakpoint-constants'

export const useCoreAppHeaderStyle = createStyles((theme) => ({
	header: {
		background: '#FFF',
		boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.05), 0px 1px 4px 0px rgba(0, 0, 0, 0.10)',
		paddingLeft: rem(HR_APP_NAVBAR_WIDTH),
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'relative',
	},

	logo: {
		position: 'absolute',
		width: 48,
		height: 48,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		margin: 'auto',

		[`@media (max-width: ${rem(HR_APP_HEADER_TABLET_BREAK_POINT)})`]: {
			right: 'auto',
			left: '15px',
		},
	},

	group: {
		height: '100%;',
	},

	anchor: {
		paddingRight: theme.spacing.sm,
		paddingLeft: theme.spacing.sm,

		'> *': {
			display: 'block',
		},
	},
}))
