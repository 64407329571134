import { useQuery } from '@tanstack/react-query'

import { EmployeeInfo } from '../types/general-types'
import { apiClient } from '../utils/api-client'

const fetchEmployee = async (endpoint: string): Promise<EmployeeInfo> => {
	const { data } = await apiClient.get(endpoint)
	return data
}

export const useGetEmployee = (id: string | null) => {
	return useQuery({
		queryKey: ['/employees/id/', id],
		queryFn: ({ queryKey }) => fetchEmployee(`${queryKey[0]}${queryKey[1]}`),
		enabled: !!id,
		retry: 2,
	})
}
