export const CoreIconHome = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='home'>
				<path
					id='primary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9 22C9.55228 22 10 21.5523 10 21V17C10 16.4477 10.4477 16 11 16H13C13.5523 16 14 16.4477 14 17V21C14 21.5523 14.4477 22 15 22H19C19.5523 22 20 21.5523 20 21V11L12 3L4 11V21C4 21.5523 4.44772 22 5 22H9ZM14 11C14 12.1046 13.1046 13 12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11Z'
					fill='#929BC9'
				/>
				<path
					id='secondary'
					d='M12.0128 4.4153L3.70711 12.721C3.31658 13.1115 2.68342 13.1115 2.29289 12.721C1.90237 12.3305 1.90237 11.6973 2.29289 11.3068L11.3068 2.29289C11.6977 1.90194 12.3317 1.90243 12.7221 2.29398L21.7082 11.3079C22.0981 11.699 22.0971 12.3322 21.706 12.7221C21.3149 13.112 20.6817 13.111 20.2918 12.7199L12.0128 4.4153Z'
					fill='#343D65'
				/>
			</g>
		</svg>
	)
}
