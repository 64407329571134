import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { AssistantRestrictedRoute } from '@assistant/ui'

const AssistantLevelup = React.lazy(() => import('@assistant/levelup'))
const AssistantAuth = React.lazy(() => import('@assistant/auth'))
const AssistantEvent = React.lazy(() => import('@assistant/event'))

import { CoreLayout } from './core-layout'
import { CoreRedirectAfterLogin } from './components/core-redirect-after-login/core-redirect-after-login'

function DefaultFallback({ children }: { children: React.ReactNode }) {
	return <React.Suspense fallback={<></>}>{children}</React.Suspense>
}

export const CoreRouter = () => {
	return (
		<Routes>
			<Route element={<AssistantRestrictedRoute />}>
				<Route path='/' element={<CoreLayout />}>
					<Route
						path='levelup/*'
						element={
							<DefaultFallback>
								<AssistantLevelup />
							</DefaultFallback>
						}
					/>

					<Route
						path='events/*'
						element={
							<DefaultFallback>
								<AssistantEvent />
							</DefaultFallback>
						}
					/>
				</Route>
			</Route>

			<Route
				path='login/*'
				element={
					<DefaultFallback>
						<AssistantAuth />
					</DefaultFallback>
				}
			/>

			<Route path='oauth-redirect' element={<CoreRedirectAfterLogin where='/events/sessions/all' />} />

			<Route path='*' element={<>Not found</>} />
		</Routes>
	)
}
