import { useNavigate } from 'react-router-dom'
import { Header, Menu, Tooltip, Divider, Anchor, Avatar, Group, UnstyledButton, Box } from '@mantine/core'
import type { DividerProps, TooltipProps } from '@mantine/core'

import { getUserIdFromLocalStorage, useGetEmployee } from '@assistant/utils'

import { CORE_ROUTES } from '../../constants/core-routes-constants'
import { CoreIconLogo } from '../../assets/icons/core-icon-logo'
import { CoreIconPageList } from '../../assets/icons/core-icon-page-list'
import { CoreIconBug } from '../../assets/icons/core-icon-bug'
import { CoreIconHeadset } from '../../assets/icons/core-icon-headset'

import { useCoreAppHeaderStyle } from './core-app-header-style'

const TOOLTIP_DEFAULT_PROPS: TooltipProps = {
	withArrow: true,
	arrowSize: 8,
	color: 'white',
	c: 'dark.8',
	p: 'xs',
	label: '',
	children: '',
}

const DIVIDER_PROPS: DividerProps = {
	orientation: 'vertical',
	color: '#e4e9ff',
}

export const CoreAppHeader = () => {
	const navigate = useNavigate()
	const { classes } = useCoreAppHeaderStyle()
	const { data: user } = useGetEmployee(getUserIdFromLocalStorage())

	function logout() {
		localStorage.removeItem('token')
		navigate('/')
	}

	return (
		<Header height={72} className={classes.header}>
			<Box className={classes.logo}>
				<CoreIconLogo />
			</Box>

			<Group className={classes.group} spacing='sm'>
				<Divider {...DIVIDER_PROPS} />

				<Tooltip {...TOOLTIP_DEFAULT_PROPS} label='Versioning history'>
					<Anchor className={classes.anchor} href={CORE_ROUTES.VERSION_HISTORY}>
						<CoreIconPageList />
					</Anchor>
				</Tooltip>

				<Divider {...DIVIDER_PROPS} />

				<Tooltip {...TOOLTIP_DEFAULT_PROPS} label='Find a bug? Report it!'>
					<Anchor className={classes.anchor} href={CORE_ROUTES.REPORT_BUG} target='_blank'>
						<CoreIconBug />
					</Anchor>
				</Tooltip>

				<Divider {...DIVIDER_PROPS} />

				<Tooltip {...TOOLTIP_DEFAULT_PROPS} label='Have an improvement idea? Let us know!'>
					<Anchor className={classes.anchor} href={CORE_ROUTES.IMPROVEMENT} target='_blank'>
						<CoreIconHeadset />
					</Anchor>
				</Tooltip>

				<Divider {...DIVIDER_PROPS} />

				<Menu withArrow>
					<Menu.Target>
						<UnstyledButton mr={44}>
							<Avatar size={48} radius={24} src={user?.profileImage} />
						</UnstyledButton>
					</Menu.Target>

					<Menu.Dropdown>
						<Menu.Item>
							<Anchor
								className={classes.anchor}
								href={`${CORE_ROUTES.ROOT}/${CORE_ROUTES.PROFILE}/${user?.id}/${CORE_ROUTES.OVERVIEW}`}
							>
								View Profile
							</Anchor>
						</Menu.Item>

						<Menu.Item onClick={logout}>Log out</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			</Group>
		</Header>
	)
}
