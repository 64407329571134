export const CoreIconPeople = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g id='people'>
				<path
					id='secondary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M5 7C5 9.20914 6.79086 11 9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7ZM16 19C16 20.1046 15.1046 21 14 21H4C2.89543 21 2 20.1046 2 19V18C2 15.2386 4.23858 13 7 13H11C13.7614 13 16 15.2386 16 18V19Z'
					fill='#343D65'
				/>
				<path
					id='primary'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M11 7C11 9.20914 12.7909 11 15 11C17.2091 11 19 9.20914 19 7C19 4.79086 17.2091 3 15 3C12.7909 3 11 4.79086 11 7ZM22 19C22 20.1046 21.1046 21 20 21H10C8.89543 21 8 20.1046 8 19V18C8 15.2386 10.2386 13 13 13H17C19.7614 13 22 15.2386 22 18V19Z'
					fill='#929BC9'
				/>
			</g>
		</svg>
	)
}
